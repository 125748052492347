<template>
  <div class="breadcrumb">
    <div class="w-60">
      <a href="#">Home</a>
      <a href="#" v-for="(menu,i) in data" :key="'menu'+i"><span></span>{{menu}}</a>
    </div>
    <div class="w-40">
      <p class="time-text">{{time}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelloWorld",
  data(){
    return {
      time:""
    }
  },
  mounted(){
    window.currentClientTime = setInterval(()=>{
      this.time = this.moment(new Date()).format(
        "DD-MM-YYYY HH:mm:ss"
      );
    },1000);
  },
  props:["data"]
};
</script>
<style>
.main_panel .under_header {
  height: 45px;
  background: #aaa;
  position: fixed;
  width: calc(100% - 300px);
  left: 300px;
  top: 65px;
  z-index: 9;
  border-top: 1px solid #fff;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.5);
}
.main_panel .under_header .breadcrumb {
  color: #fff;
  height: 45px;
  line-height: 45px;
  padding: 0 40px;
  width: 100%;
  float: left;
}
.main_panel .under_header .breadcrumb a {
  display: inline-block;
  margin-right: 20px;
  color: #000;
}
.main_panel .under_header .breadcrumb span {
  display: inline-block;
  margin-right: 20px;
  background: url(../assets/ico-bread-arrow.png) no-repeat;
  width: 14px;
  height: 14px;
}
.w-60{
  width:60%;
  float:left;
}
.w-40{
  width:40%; 
  float:left;
}
.time-text{
  color:black;
  text-align: right;
}
</style>